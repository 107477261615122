
<script setup lang="ts">
    import { computed, watch, onMounted } from "vue";
    import { type DataObject, type DataItemModel } from "o365-dataobject";
    import { OPaginator } from "o365-data-pagination";
    import { isMobile } from "o365.GlobalState.ts";
    import { useDataObjectEventListener, useAsyncComponent } from "o365-vue-utils";

    import SectionSwitch from "home.vue.components.SectionSwitch.vue";

    export interface IProps {
        title: string;
        text?: string;
        href?: string;
        data?: Array<DataItemModel>;
        dataObject: DataObject;
        switch1Text?: string;
        switch2Text?: string;
    };

    const MCardGroup = useAsyncComponent("o365-mobile/MCardGroup");

    const props = defineProps<IProps>();
    const model_switch1 = defineModel<boolean>("switch1");
    const model_switch2 = defineModel<boolean>("switch2");

    const data = computed(() => props.data ?? props.dataObject.data);

    const isLoading = computed(() => props.dataObject.state.isLoading || props.dataObject.state.isRowCountLoading || props.dataObject.rowCount == null);

    useDataObjectEventListener(props.dataObject, "DataLoaded", () => {
        if (isMobile.value) {
            props.dataObject.recordSource.loadRowCount();
        }
    });

    onMounted(() => {
        if (isMobile.value) {
        } else {
            props.dataObject.pagedData.enable();
        }
    });
</script>

<template>
    <template v-if="dataObject.data.length > 0 || (dataObject.state.isLoaded && dataObject.state.isLoading)">
        <!-- TODO: remove mt-3 and do gap in App.vue once all components have been converted -->
        <div class="mt-3">
            <div :class="{ 'px-1': isMobile }">
                <div class="d-flex justify-content-between align-items-end gap-2">
                    <a class="flex-shrink-0 d-flex align-items-end gap-1 font-4 fw-medium" :class="{ 'text-black': !href }" style="margin: -0.5em; padding: 0.5em;" :href="href">
                        <div>{{ title }}</div>
                        <template v-if="isMobile">
                            <template v-if="isLoading">
                                <div class="ms-1">
                                    <div class="spinner-border spinner-border-sm" />
                                </div>
                            </template>
                        </template>
                        <template v-if="isMobile">
                            <template v-if="!isLoading">
                                <span>({{ dataObject.rowCount }})</span>
                            </template>
                        </template>
                    </a>
                    <template v-if="!isMobile && text">
                        <div class="flex-grow-1 text-muted text-truncate">
                            {{ text }}
                        </div>
                    </template>
                    <template v-if="switch1Text || switch2Text || $slots.switches">
                        <div class="flex-shrink-0">
                            <slot name="switches">
                                <template v-if="switch1Text">
                                    <SectionSwitch v-model="model_switch1" :text="switch1Text" />
                                </template>
                                <template v-if="switch2Text">
                                    <SectionSwitch v-model="model_switch2" :text="switch2Text" />
                                </template>
                            </slot>
                        </div>
                    </template>
                </div>
                <template v-if="isMobile && text">
                    <div class="flex-grow-1 text-muted text-truncate">
                        {{ text }}
                    </div>
                </template>
            </div>
            <template v-if="!isMobile">
                <hr class="my-1" />
            </template>
            <div class="mt-1 d-flex flex-column" :class="{ 'gap-0': isMobile }">
                <MCardGroup>
                    <template v-for="row in data">
                        <slot name="row" :row="row" />
                    </template>
                </MCardGroup>
            </div>
            <div>
                <template v-if="isMobile">
                    <template v-if="!dataObject.dynamicLoading.lastPageReached">
                        <div class="d-flex justify-content-end">
                            <a class="btn btn-link" @click="dataObject.loadNextPage({ timeout: 30000 })" :disabled="dataObject.state.isNextPageLoading">
                                <template v-if="dataObject.state.isNextPageLoading">
                                    <div class="spinner-border spinner-border-sm text-primary" />
                                </template>
                                <template v-else>
                                    {{ $t("Show more") }}
                                </template>
                            </a>
                        </div>
                    </template>
                </template>
                <template v-else>
                    <OPaginator :dataObject="dataObject" />
                </template>
            </div>
        </div>
    </template>
</template>
